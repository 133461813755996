import { Component } from "react";
import { HStack, VStack, Box, Pressable } from "native-base";
import moment from "moment";
import _ from "lodash";
import clockIcon from "assets/img/clock.svg";

import VehicleOptions from "../VehicleOptions";
import {
  // DelayTransport,
  Tag,
  Text,
  Button,
  PatientStatus,
  NotSpecified,
} from "components";
import { getPhonesFromService } from "functions";
import { RIDE_STATUS } from "global";
import Tooltip from "components/Tooltip";

function formatWaitingTime(waitingTime, status) {
  return ["onway", "onboard"].includes(status) &&
    !_.isEmpty(waitingTime) &&
    _.isInteger(waitingTime.duration_in_traffic)
    ? moment.utc(waitingTime.duration_in_traffic * 1000).format("HH:mm")
    : "--:--";
}

const EmergencyTime = ({ emergencyTime, datetime, tooltipLabel = "" }) => (
  <Tooltip label={tooltipLabel} placement="top">
    <HStack justifyContent="space-between" w="full">
      <img src={clockIcon} style={{ width: 25 }} alt="" />
      <Text
        fontSize="16px"
        fontWeight={900}
        color={
          emergencyTime >= 60
            ? "success"
            : emergencyTime >= 30
            ? "warning"
            : "danger"
        }
      >
        {moment(datetime).add(emergencyTime, "minutes").format("HH:mm")}
      </Text>
    </HStack>
  </Tooltip>
);

class OnGoingRidesCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMoreInfo: false,
    };

    this.onPressAssignVehicle = this.onPressAssignVehicle.bind(this);
    this.onRideShowMoreInfoToggle = this.onRideShowMoreInfoToggle.bind(this);
    this.onPressShowMap = this.onPressShowMap.bind(this);

    this.PATIENT_STATUS_TEXT = ["waiting", "admission", "ready"].map(
      (item) => ({
        key: item,
        value: this.props.t(`filterModal.patientStatus.${item}`, {
          lng: this.props.i18n.language,
        }),
      })
    );
  }

  onPressAssignVehicle() {
    this.props.onPressAcceptRideButton(this.props.ride);
  }

  onPressShowMap() {
    this.props.onShowMap(this.props.ride);
  }

  onRideShowMoreInfoToggle() {
    this.setState(
      Object.assign({}, this.state, {
        isShowMoreInfo: !this.state.isShowMoreInfo,
      })
    );
  }

  render() {
    const { t, i18n, ridesNeedAssign, downloadFiles } = this.props;
    const {
      _id,
      rideNumber,
      patient,
      status,
      dropoff,
      pickup,
      transporter,
      datetime,
      vehicleOptions,
      serie,
      samu,
      service,
      intervention,
      observation,
      subject,
      waitingTime = {},
      note,
      isRoundTrip,
      files = [],
      created = { emergencyTime: "" },
    } = this.props.ride;
    const rideAssigned = transporter.vehicle !== null;
    const statusColor = `status.${rideAssigned ? status : "unassigned"}`;
    const textStatusColor = `textStatus.${
      rideAssigned ? status : "unassigned"
    }`;
    const statusText = t(`rideStatus.${rideAssigned ? status : "unassigned"}`, {
      lng: i18n.language,
    });
    const { isShowMoreInfo } = this.state;
    // const isShowPatientStatus = samu ? false : true;
    // const isShowRideDestination = pickup.address ? true : false;
    const rideStarted = !["incoming", "pending"].includes(status);
    const servicePhones = getPhonesFromService(service);

    return (
      <Pressable
        onPress={this.onRideShowMoreInfoToggle}
        mb={this.props.mb || 0}
        position="relative"
        borderWidth={1}
        boxSize="border-box"
        rounded="4px"
        borderColor={rideAssigned ? "gray" : "status.unassigned"}
        _hover={{ borderColor: "rgba(2, 150, 174, 0.6)" }}
        _pressed={{ borderColor: "#0296AE" }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxWidth="986px"
        py="2"
        px="27px"
        bg="ligth"
      >
        <HStack w="full">
          <VStack space={4} w="295px" pr={2}>
            <HStack space="40px">
              <VStack space={5} h="110px">
                <Text fontSize="16px" fontWeight={900}>
                  {rideNumber}
                </Text>
                <Text fontSize="16px" fontWeight={700}>
                  {moment(datetime).format("DD MMM HH:mm")}
                </Text>
                {samu && !rideStarted ? (
                  <EmergencyTime
                    datetime={datetime}
                    emergencyTime={created.emergencyTime}
                    tooltipLabel={t(
                      `TransporterReservationsPage.samuEmergencyTime`,
                      { lng: i18n.language }
                    )}
                  />
                ) : (
                  <HStack justifyContent="space-between" w="full">
                    <img src={clockIcon} style={{ width: 25 }} alt="" />
                    <Text fontSize="16px" fontWeight={900}>
                      {formatWaitingTime(waitingTime, status)}
                    </Text>
                  </HStack>
                )}
              </VStack>

              <VStack space={2} flex={1}>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Prise en charge
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {pickup.address}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Dépose
                  </Text>

                  {dropoff.address ? (
                    <Text
                      nbOfLines={2}
                      fontSize="12px"
                      fontWeight="500"
                      numberOfLines={2}
                    >
                      {dropoff.address}
                    </Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              </VStack>
            </HStack>

            {isShowMoreInfo && samu && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor="gray">
                <Text fontSize="12px" fontWeight="600" color="gray">
                  Informations complémentaires du lieu d'intervention
                </Text>

                <HStack space={4} w="full">
                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Batiment
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.batiment}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Digit Code
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.digicode}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Étage
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.etage}
                    </Text>
                  </VStack>
                </HStack>
                <HStack space={4} w="full">
                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Complement
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention?.complement}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </VStack>

          <HStack w="264px">
            <VStack
              space={1}
              pl={4}
              borderLeftWidth={1}
              borderColor="gray"
              flex={1}
            >
              <Text
                fontSize="16px"
                fontWeight={900} 
                isTruncated={!isShowMoreInfo}
              >
                {`${_.capitalize(patient?.firstname) || ""} ${_.capitalize(
                  patient?.lastname
                )}`}
              </Text>
              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Age
                </Text>
                {patient?.age ? (
                  <Text
                    fontSize="12px"
                    fontWeight={900}
                  >{`${patient.age} ans`}</Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>

              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Motif
                </Text>
                {subject ? (
                  <Text fontSize="12px" fontWeight={900}>
                    {subject}
                  </Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>
              {isShowMoreInfo && (
                <VStack space={1}>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° téléphone
                    </Text>
                    {patient?.phone ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient.phone}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° sécurité sociale
                    </Text>
                    <NotSpecified />
                  </VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Observation
                  </Text>
                  {observation ? (
                    <Text fontSize="12px" fontWeight="700" numberOfLines={3}>
                      {observation}
                    </Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              )}
            </VStack>

            <VStack flex={1}>
              <Text fontSize="12px" fontWeight="500" mb="2px" color="gray">
                Status Patient
              </Text>
              <PatientStatus
                value={patient.status}
                steps={this.PATIENT_STATUS_TEXT}
                t={t}
                i18n={i18n}
              />
              {isShowMoreInfo && (
                <VStack
                  space={1}
                  mt={2}
                  pt={2}
                  borderTopWidth={1}
                  borderColor="gray"
                >
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Service
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    {service ? (
                      `${service?.company?.name} - ${service?.name}`
                    ) : (
                      <NotSpecified />
                    )}
                  </Text>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Contact
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    {servicePhones ? servicePhones : <NotSpecified />}
                  </Text>

                  <VStack pt={2} borderTopWidth={1} borderColor="gray">
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Note
                    </Text>
                    <Text fontSize="12px" fontWeight="500">
                      {note}
                    </Text>
                  </VStack>


                </VStack>
              )}
            </VStack>
          </HStack>

          <VStack
            space={1}
            flex={1}
            pl={4}
            borderLeftWidth={1}
            borderColor="gray"
          >
            <HStack space="44px">
              <VStack space={1} w="132px">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Véhicule
                </Text>
                <Text fontSize="12px" fontWeight="700">
                  {transporter?.vehicle?.plate.toUpperCase() || ""}
                </Text>
                <Text fontSize="12px" fontWeight="500">
                  {transporter?.vehicle?.phone || ""}
                </Text>
                {!rideAssigned && (
                  <Button
                    third
                    borderColor="danger"
                    color="danger"
                    mb={2}
                    onPress={this.onPressAssignVehicle}
                  >
                    {t("TransporterReservationsPage.button_assign_vehicle", {
                      lng: i18n.language,
                    }).toUpperCase()}
                  </Button>
                )}
              </VStack>

              <Box flex={1}>
                <VStack space={2} alignItems="flex-start">
                  <Tag
                    text={"● " + statusText}
                    color={textStatusColor}
                    bg={statusColor}
                  />
                  <Button
                    third
                    color={rideStarted ? "default" : "gray"}
                    borderColor={rideStarted ? "default" : "gray"}
                    py="1px"
                    px="10px"
                    borderWidth="1px"
                    onPress={this.onPressShowMap}
                    disabled={!rideStarted}
                  >
                    {t("TransporterReservationsPage.button_localisation", {
                      lng: i18n.language,
                    })}
                  </Button>
                  {(serie || samu || isRoundTrip) && (
                    <Tag
                      text={serie ? "SERIE" : samu ? "SAMU" : "A/R"}
                      color={"white"}
                      bg={"secondary"}
                      py="2px"
                    />
                  )}
                  {isShowMoreInfo && !samu && files.length > 0 && (
                    <VStack>
                      <Text
                        fontSize="12px"
                        fontWeight="500"
                        mb="2px"
                        color="gray"
                      >
                        {files.length}{" "}
                        {files.length == 1
                          ? "fichier disponible"
                          : `fichiers disponibles`}
                      </Text>
                      <Button
                        secondary
                        rounded="5px"
                        onPress={() => downloadFiles(files, patient.lastname)}
                      >
                        Télécharger
                      </Button>
                    </VStack>
                  )}
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </HStack>
      </Pressable>
    );
  }
}

export default OnGoingRidesCard;
