import { Component } from "react";
import { HStack, VStack, Pressable, Box, Center } from "native-base";
import _ from "lodash";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import { Text } from "../Text";
import Tag from "../Tag";
import Button from "../Button";
import { getPhonesFromService } from "functions";

function formatedDateRecap(d) {
  if (!d || !moment(d).isValid()) return "...";
  return moment(d).format("HH:mm");
}

class HistoryRidesCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMoreInfo: false,
    };

    this.onRideShowMoreInfoToggle = this.onRideShowMoreInfoToggle.bind(this);
  }

  onRideShowMoreInfoToggle() {
    this.setState(
      Object.assign({}, this.state, {
        isShowMoreInfo: !this.state.isShowMoreInfo,
      })
    );
  }

  render() {
    const { t, i18n, downloadFiles } = this.props;
    const {
      rideNumber,
      patient,
      status,
      dropoff,
      pickup,
      transporter,
      datetime,
      vehicleOptions,
      serie,
      samu,
      intervention,
      observation,
      subject,
      summary,
      isRoundTrip,
      note,
      files,
      service,
    } = this.props.ride;
    const { isShowMoreInfo } = this.state;
    const statusColor = `status.${status}`;
    const textStatusColor = `textStatus.${status}`;
    const statusText = t(`rideStatus.${status}`, {
      lng: i18n.language,
    });
    const servicePhones = getPhonesFromService(service);

    function NotSpecified() {
      return (
        <Text fontSize="12px" italic>
          {t("TransporterReservationsPage.not_specified", {
            lng: i18n.language,
          })}
        </Text>
      );
    }

    return (
      <Pressable
        onPress={this.onRideShowMoreInfoToggle}
        mb={this.props.mb || 0}
        position="relative"
        borderWidth={1}
        boxSize="border-box"
        borderColor="gray"
        rounded="4px"
        _hover={{ borderColor: "rgba(2, 150, 174, 0.6)" }}
        _pressed={{ borderColor: "#0296AE" }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxWidth="986px"
        py="2"
        px="30px"
        bg="ligth"
      >
        <HStack w="full">
          <VStack space={4} w="295px" pr={2}>
            <HStack space="40px">
              <VStack space={5} h="110px">
                <Text fontSize="16px" fontWeight={900}>
                  {rideNumber}
                </Text>
                <Text fontSize="16px" fontWeight={700}>
                  {moment(datetime).format("DD MMM HH:mm")}
                </Text>
                {/* <HStack justifyContent="space-between" w="full">
                  <img src={clockIcon} style={{ width: 25 }} />
                  <Text fontSize="16px" fontWeight={900}>--:--</Text>
                </HStack> */}
              </VStack>

              <VStack space={2} flex={1}>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Prise en charge
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {pickup.address}
                  </Text>
                </VStack>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Dépose
                  </Text>

                  {dropoff.address ? (
                    <Text
                      nbOfLines={2}
                      fontSize="12px"
                      fontWeight="500"
                      numberOfLines={2}
                    >
                      {dropoff.address}
                    </Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              </VStack>
            </HStack>

            {isShowMoreInfo && samu && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor="gray">
                <Text fontSize="12px" fontWeight="600" color="gray">
                  Informations complémentaires du lieu d'intervention
                </Text>

                <HStack space={4} w="full">
                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Batiment
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention?.batiment}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Digit Code
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention?.digicode}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Étage
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention?.etage}
                    </Text>
                  </VStack>
                </HStack>
                <HStack space={4} w="full">
                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Complement
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention?.complement}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </VStack>

          <HStack w="264px">
            <VStack
              space={1}
              pl={4}
              borderLeftWidth={1}
              borderColor="gray"
              flex={1}
            >
              <Text
                fontSize="16px"
                fontWeight={900}
                isTruncated={!isShowMoreInfo}
              >
                {`${patient?.firstname || ""} ${patient?.lastname}`}
              </Text>
              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Age
                </Text>
                {patient?.age ? (
                  <Text
                    fontSize="12px"
                    fontWeight={900}
                  >{`${patient.age} ans`}</Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>

              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Motif
                </Text>
                {subject ? (
                  <Text fontSize="12px" fontWeight={900}>
                    {subject}
                  </Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>
              {isShowMoreInfo && (
                <VStack space={1}>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° téléphone
                    </Text>
                    {patient?.phone ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient.phone}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° sécurité sociale
                    </Text>
                    <NotSpecified />
                  </VStack>

                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Observation
                  </Text>
                  {observation ? (
                    <Text numberOfLines={3}>{observation}</Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              )}
            </VStack>

            <VStack flex={1} space={2}>
              <VStack space={1}>
                <Text fontSize="12px" fontWeight="500" mb="2px" color="gray">
                  Notes
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  numberOfLines={isShowMoreInfo ? 100 : 2}
                >
                  {note}
                </Text>
              </VStack>
              {_.isEmpty(note) && <NotSpecified />}
              {files.length > 0 && (
                <VStack space={1} alignItems="start">
                  <Text fontSize="12px" fontWeight="500" mb="2px" color="gray">
                    {files.length}{" "}
                    {files.length == 1
                      ? "fichier disponible"
                      : `fichiers disponibles`}
                  </Text>
                  <Button
                    secondary
                    rounded="5px"
                    onPress={() => downloadFiles(files, patient.lastname)}
                  >
                    Télécharger
                  </Button>
                </VStack>
              )}
              {isShowMoreInfo && (
                <VStack
                  space={1}
                  mt={2}
                  pt={2}
                  borderTopWidth={1}
                  borderColor="gray"
                >
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Service
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    {service ? (
                      `${service?.company?.name} - ${service?.name}`
                    ) : (
                      <NotSpecified />
                    )}
                  </Text>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Contact
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    {servicePhones ? servicePhones : <NotSpecified />}
                  </Text>
                </VStack>
              )}
            </VStack>
          </HStack>

          <VStack
            space={1}
            w="365px"
            pl={4}
            borderLeftWidth={1}
            borderColor="gray"
          >
            <HStack space="44px">
              <VStack space={1} w="132px">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Véhicule
                </Text>
                {status === "incoming" && <NotSpecified />}
                <Text fontSize="12px" fontWeight="700">
                  {transporter?.vehicle?.plate.toUpperCase() || ""}
                </Text>
                <Text fontSize="12px" fontWeight="500">
                  {transporter?.vehicle?.phone || ""}
                </Text>
              </VStack>

              <Box flex={1}>
                <VStack space={2} alignItems="flex-start">
                  <Tag
                    text={"● " + statusText}
                    color={textStatusColor}
                    bg={statusColor}
                  />
                  {(serie || samu || isRoundTrip) && (
                    <Tippy
                      content={
                        <Text fontSize="12px" fontWeight="500" color="white">
                          {serie
                            ? "Transport provenant d'une série"
                            : samu
                            ? "Intervention SAMU"
                            : "Transport aller-retour soit 2 interventions"}
                        </Text>
                      }
                      placement="bottom"
                      hideOnClick={false}
                      duration={0}
                    >
                      <Center>
                        <Tag
                          cursor="pointer"
                          text={serie ? "SERIE" : samu ? "SAMU" : "A/R"}
                          color={"white"}
                          bg={"secondary"}
                          py="2px"
                        />
                      </Center>
                    </Tippy>
                  )}
                </VStack>
              </Box>
            </HStack>

            {isShowMoreInfo && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor="gray">
                <Text fontSize="12px" fontWeight="600" color="gray">
                  Récapitulatif de l'intervention
                </Text>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Heure de départ vers l'intervention
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    {formatedDateRecap(summary?.onway)}
                  </Text>
                </VStack>
                <HStack space={4}>
                  <VStack>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Heure de prise en charge
                    </Text>
                    <Text fontSize="12px" fontWeight="500">
                      {formatedDateRecap(summary?.onboard)}
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Heure de dépose
                    </Text>
                    <Text fontSize="12px" fontWeight="500">
                      {formatedDateRecap(summary?.arrived)}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </VStack>
        </HStack>
      </Pressable>
    );
  }
}

export default HistoryRidesCard;
